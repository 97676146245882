/**
 *
 * Use this to add the Tailwind CSS framework.
 *
 * Please note that in PhpStorm, custom properties from tailwind-config are not yet supported.
 */

/**
 * Excluded because Bootstrap 3 already has base styles
 *
 * This injects Tailwind's base styles, which is a combination of
 * Normalize.css and some additional base styles.
 *
 * You can see the styles here:
 * https://github.com/tailwindcss/tailwindcss/blob/master/css/preflight.css
 *
 * @tailwind preflight;
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/preflight.css";
 */
/*@import "../../../../node_modules/tailwindcss/preflight.css";*/

/**
 * This injects any component classes registered by plugins.
 *
 * @tailwind components;
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/components";
 */
/*@import "../../../../node_modules/tailwindcss/components.css";*/

/**
 * Here you would add any of your custom component classes; stuff that you'd
 * want loaded *before* the utilities so that the utilities could still
 * override them.
 *
 * Example:
 *
 * .btn { ... }
 * .form-input { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "components/buttons";
 * @import "components/forms";
 */

/**
 * This injects all of Tailwind's utility classes, generated based on your
 * config file.
 *
 * @tailwind utilities;
 *
 * If using `postcss-import`, use this import instead:
 *
 * @import "tailwindcss/utilities";
 */
@import "../../../../node_modules/tailwindcss/utilities.css";

/**
 * Here you would add any custom utilities you need that don't come out of the
 * box with Tailwind.
 *
 * Example :
 *
 * .bg-pattern-graph-paper { ... }
 * .skew-45 { ... }
 *
 * Or if using a preprocessor or `postcss-import`:
 *
 * @import "utilities/background-patterns";
 * @import "utilities/skew-transforms";
 */

/*@import "../../../../node_modules/font-awesome/css/font-awesome.css";*/

/**
 * Add whatever was handy in Bootstrap
 */

html {
	font-size: 16px;
}

.navbar-nav > li.active > a.text-white {
	color: #ffffff;
}

.navbar-nav > li.active > a.text-white.hover\:text-pink:hover {
	color: #ff4262;
}

.navbar-nav > li.active > a.text-white.focus\:text-pink:focus {
	color: #ff4262;
}

.navbar-nav > li.active > a.text-white.active\:text-pink:active {
	color: #ff4262;
}

